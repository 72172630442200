export function getBaseUrl(): string {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'https://stage.wellnite.co';
    case 'production':
      return 'https://www.wellnite.co';
    default:
      return 'https://stage.wellnite.co';
  }
}

export const formatTag = (tag: string) => {
  const words = tag.split(/(?=[A-Z])/).filter(word => word.length > 0);

  const formattedWords = words.map(word => {
    if (word === word.toUpperCase() && word.length > 1) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  return formattedWords.join(' ');
};
