'use client';
import {useState} from 'react';
import {Link} from '@website/navigation';
import {
  WellniteIcon,
  WellniteMobileIcon,
} from '@website/components/shared/icons/WellniteIcon';
import {LanguagePickButton} from './LanguagePickButton';
import RedirectToExternalLinkButton from '../../basics/RedirectToExternalLinkButton';
import {getBaseUrl} from '@website/lib/services/utils';
import {useTranslations} from 'next-intl';
import {Rectangle} from '../../icons/Rectangle';
import {HeaderMobileMenu} from './HeaderMobileMenu';
import {CaretDownIcon, CaretUpIcon} from '../../icons/Pointers';

export const BenefitsHeader = () => {
  const links = [
    {text: 'solutions', href: '#'},
    {text: 'brokers', href: '/benefits/brokers'},
    {text: 'articles', href: '/blog'},
  ];
  const mobileLinks = [
    {
      text: 'economicValue',
      href: '/benefits/solutions-economic-value',
    },
    {
      text: 'employers',
      href: '/benefits/solutions-employers',
    },
    {
      text: 'consultants',
      href: '/benefits/solutions-consultants',
    },
    {
      text: 'brokers',
      href: '/benefits/brokers',
    },
    {text: 'articles', href: '/blog'},
  ];
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const t = useTranslations('Nav');

  const solutionsLinks = [
    {
      text: 'economicValue',
      href: '/benefits/solutions-economic-value',
      description: 'decreasedHealthcareCosts',
    },
    {
      text: 'employers',
      href: '/benefits/solutions-employers',
      description: 'ensureRapidAccess',
    },
    {
      text: 'consultants',
      href: '/benefits/solutions-consultants',
      description: 'shapeFuture',
    },
  ];

  const toggleSolutionsDropdown = () => {
    setIsSolutionsOpen(!isSolutionsOpen);
  };

  return (
    <>
      <nav className="w-full flex items-center justify-between space-x-10 bg-white text-black border-b border-gray-200 px-6 py-6">
        <div className="flex w-full lg:w-auto items-center justify-between lg:justify-start space-x-8">
          <Link
            href="/"
            className="flex items-center"
            aria-label="Wellnite Home"
          >
            <WellniteIcon className="hidden lg:inline-block" />
            <WellniteMobileIcon className="lg:hidden" />
          </Link>
          <HeaderMobileMenu className="lg:hidden" links={mobileLinks} />
          <ul className="hidden lg:flex items-center space-x-8 text-base">
            {links.map(link => {
              if (link.text === 'solutions') {
                return (
                  <button
                    onClick={toggleSolutionsDropdown}
                    className="hover:text-gray-500 focus:outline-none mx-auto flex items-center space-x-2"
                    key={`nav-link-${link.text}`}
                  >
                    {t('solutions')}
                    {isSolutionsOpen ? (
                      <CaretUpIcon
                        fillColor="currentColor"
                        strokeColor="currentColor"
                        strokeWidth={0.5}
                      />
                    ) : (
                      <CaretDownIcon
                        fillColor="currentColor"
                        strokeColor="currentColor"
                        strokeWidth={0.5}
                      />
                    )}
                  </button>
                );
              }
              return (
                <li key={`nav-link-${link.text}`}>
                  <Link
                    href={link.href}
                    className="hover:text-blue-700"
                    aria-label={link.text}
                  >
                    {t(link.text)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="hidden lg:flex items-center space-x-8 text-base text-black">
          <LanguagePickButton />
          <div aria-hidden>
            <Rectangle />
          </div>
          <RedirectToExternalLinkButton
            classes="font-bold hover:text-blue-500"
            btnType="custom"
            externalLink={`${getBaseUrl()}/login`}
          >
            <span className="font-bold">{t('logIn')}</span>
          </RedirectToExternalLinkButton>
          <RedirectToExternalLinkButton
            btnType="custom"
            classes="bg-pink-200 hover:bg-white hover:border-1 hover:border-pink-300 rounded-full px-4 py-3"
            externalLink="https://airtable.com/appbh98N3LwF7cyyZ/shrcKyZxR49WcuCVv"
          >
            <span className="font-bold">{t('requestDemo')}</span>
          </RedirectToExternalLinkButton>
        </div>
      </nav>

      {isSolutionsOpen && (
        <div className="w-full hidden lg:flex justify-between px-16 py-4 bg-white border-b border-gray-200">
          {solutionsLinks.map(link => (
            <div key={`solutions-dropdown-${link.text}`}>
              <Link href={link.href} aria-label={link.text}>
                <h3 className="font-bold">{t(link.text)}</h3>
                <p className="pr-32">{t(link.description)}</p>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
