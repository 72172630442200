'use client';

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import {ChevronDownIcon} from '@website/components/shared/icons/ChevronDownIcon';
import {LanguageIcon} from '@website/components/shared/icons/LanguageIcon';
import clsx from 'clsx';
import {Link} from '@website/navigation';
import React, {Fragment, useMemo} from 'react';
import {useLocale} from 'next-intl';
import {usePathname} from 'next/navigation';
import {languages} from '@website/constants';
import {Language} from '@website/types/language.types';

export interface LanguagePickButtonProps {
  isMobile?: boolean;
}

export const LanguagePickButton: React.FC<LanguagePickButtonProps> = ({
  isMobile,
}) => {
  const splitLanguages = languages.reduce(
    (prev, curr) => {
      prev[curr.code] = [...(prev[curr.code] ?? []), curr];
      return prev;
    },
    {} as {[key: string]: Language[]},
  );
  const locale = useLocale()?.split('-');
  const currentLanguage =
    languages.find(
      lang =>
        lang.code === locale[0] && lang.countryCode.toLowerCase() === locale[1],
    ) ?? languages[0];
  const pathname = usePathname();
  const currentPathName = useMemo(
    () => pathname?.replace(/^\/[a-z]{2}-[a-z]{2}(\/.*)?/, '$1'),
    [pathname],
  );

  return (
    <Menu as="div" className="relative lg:inline-block">
      {({open}) => (
        <>
          <MenuButton
            className={clsx('w-full lg:w-auto outline-none', {
              'lg:bg-blue-100 lg:text-blue-500': open,
              'hover:bg-blue-100 hover:text-blue-500 rounded-lg p-2 flex items-center justify-center':
                !isMobile,
            })}
            aria-label="Show available languages"
          >
            <span className="hidden lg:inline-flex items-center space-x-2">
              <LanguageIcon />
              <span className="inline-block">
                {currentLanguage.countryCode}
              </span>
            </span>
            <span
              className={clsx(
                'flex lg:hidden items-center justify-between py-4',
                {
                  'border-b border-gray-200': !open,
                },
              )}
            >
              <span className="inline-block">
                <span className="font-medium text-gray-900">
                  {currentLanguage.country}
                </span>
                {' - '}
                <span className="text-gray-700">
                  {currentLanguage.language}
                </span>
              </span>
              <ChevronDownIcon />
            </span>
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              // when adding new language change width accordingly, portugese - w-[600px]
              className={clsx('bg-white focus:outline-none', {
                'absolute right-0 w-[450px] flex justify-center mt-2 origin-top-right divide-y divide-gray-100 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5':
                  !isMobile,
                'relative w-full mt-2 border-b border-gray-200 pb-4': isMobile,
              })}
              style={
                !isMobile
                  ? {right: '0', left: 'auto', transform: 'translateX(-1%)'}
                  : {}
              }
            >
              <div
                className={clsx(
                  'lg:p-4 flex flex-col justify-start items-start lg:flex-row lg:space-x-2 flex-wrap',
                  {},
                )}
              >
                {Object.keys(splitLanguages).map((code, key) => (
                  <div
                    key={`lng-items-${key}`}
                    className="flex flex-col space-y-1 w-full lg:w-auto"
                  >
                    {splitLanguages[code].map((lng, index) => (
                      <MenuItem key={`lng-menu-item-${index}`}>
                        <Link
                          href={currentPathName?.length ? currentPathName : '/'}
                          locale={`${lng.code}-${lng.countryCode.toLowerCase()}`}
                          className="block text-block text-black hover:bg-blue-100 rounded px-2 py-2 text-base transition duration-300"
                          aria-label={`Show in ${lng.language}`}
                        >
                          <span className="inline-block text-gray-900 lg:font-bold">
                            {lng.country}
                          </span>
                          <span className="inline-block">&nbsp;-&nbsp;</span>
                          <span className="inline-block text-gray-700">
                            {lng.language}
                          </span>
                        </Link>
                      </MenuItem>
                    ))}
                  </div>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};
