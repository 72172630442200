import {notFound} from 'next/navigation';
import {getRequestConfig} from 'next-intl/server';
import {LanguageEnum} from './types/language.types';

interface Language {
  symbol: string;
  name: string;
}

export const supportedLanguages: Language[] = [
  {symbol: 'EN-US', name: 'English'},
  {symbol: 'EN-CA', name: 'English'},
  {symbol: 'EN-SA', name: 'English'},
  {symbol: 'EN-AU', name: 'English'},
  {symbol: 'EN-NZ', name: 'English'},
  {symbol: 'ES-CL', name: 'Spanish'},
  {symbol: 'ES-ES', name: 'Spanish'},
  {symbol: 'ES-MX', name: 'Spanish'},
  {symbol: 'ES-CO', name: 'Spanish'},
  // {symbol: 'PT-BR', name: 'Portugese'},
];

const languageSymbolsLowercase: string[] = supportedLanguages.map(lang =>
  lang.symbol.toLowerCase(),
);

const initialLocales: string[] = [];

const combinedLocales: string[] = [
  ...initialLocales,
  ...languageSymbolsLowercase,
];

export const locales: string[] = combinedLocales;

export default getRequestConfig(async ({locale}) => {
  if (!locales.includes(locale)) notFound();
  const languageCode = locale.toLowerCase().split('-')[0] as LanguageEnum;
  const translationFile = LanguageEnum[languageCode] || LanguageEnum.en;
  return {
    messages: (await import(`../messages/${translationFile}.json`)).default,
  };
});
