import clsx from "clsx";
import React from "react";

interface HamburgerMenuIconProps {
  isOpen?: boolean;
}

export const HamburgerMenuIcon = ({ isOpen }: HamburgerMenuIconProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        "transition-transform duration-300 ease-in-out min-w-[30px]"
      )}
    >
      <rect
        x="4"
        y="8"
        width="22"
        height="2"
        fill="currentColor"
        className={`transition-transform duration-300 ease-in-out origin-[11px_50%] ${isOpen ? "transform rotate-45 translate-y-[0.1rem] -translate-x-[2px]" : ""}`}
      />
      <rect
        x="4"
        y="20"
        width="22"
        height="2"
        fill="currentColor"
        className={`transition-transform duration-300 ease-in-out origin-[11px_50%] ${isOpen ? "transform -rotate-45 -translate-y-[0.1rem] -translate-x-[2px]" : ""}`}
      />
    </svg>
  );
};
