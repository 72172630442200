export enum LanguageEnum {
  en = 'en',
  es = 'es',
  pt = 'pt',
}

export interface Language {
  country: string;
  countryCode: string;
  language: string;
  code: string;
}
