'use client';

import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react';
import {HamburgerMenuIcon} from '@website/components/shared/icons/HamburgerMenuIcon';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import {LanguagePickButton} from './LanguagePickButton';
import {useTranslations} from 'next-intl';
import {CtaButton, CtaButtonProps} from './CtaButton';
import RedirectToExternalLinkButton from '../../basics/RedirectToExternalLinkButton';
import {getBaseUrl} from '@website/lib/services/utils';

export interface HeaderMobileMenuProps {
  className?: string;
  links: {text: string; href: string; external?: boolean}[];
  linkButton?: CtaButtonProps['linkButton'];
}

export const HeaderMobileMenu: React.FC<HeaderMobileMenuProps> = ({
  className,
  links,
  linkButton = 'get-started',
}) => {
  const t = useTranslations('Nav');
  return (
    <Menu as="div" className={clsx('relative', className)}>
      {({open}) => (
        <>
          <MenuButton
            className="outline-none flex items-center justify-center space-x-2 rounded-lg p-2"
            aria-label="Menu"
          >
            <HamburgerMenuIcon isOpen={open} />
          </MenuButton>
          <MenuItems
            anchor="bottom"
            className="absolute z-10 overflow-auto pt-6 flex flex-col w-full h-full bg-white text-black focus:outline-none"
          >
            <div className="px-4 pb-10 border-t border-gray-200">
              {links.map((link, index) => (
                <MenuItem key={`lng-mobile-menu-item-${index}`}>
                  {link.external ? (
                    <a
                      href={link.href}
                      className="flex items-center py-4 border-b border-gray-200"
                      role="link"
                      aria-label={link.text}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(link.text)}
                    </a>
                  ) : (
                    <Link
                      href={link.href}
                      className="flex items-center py-4 border-b border-gray-200"
                      role="link"
                      aria-label={link.text}
                    >
                      {t(link.text)}
                    </Link>
                  )}
                </MenuItem>
              ))}
              <LanguagePickButton isMobile={true} />
              <div className="mt-6 flex flex-col space-y-6">
                <CtaButton linkButton={linkButton} />
                <RedirectToExternalLinkButton
                  classes="w-full mt-4 lg:w-auto lg:mt-0 !border !border-gray-300 hover:bg-gray-100 rounded-full px-4 py-3 font-bold text-sm"
                  btnType="custom"
                  externalLink={`${getBaseUrl()}/login`}
                >
                  <span className="font-bold">{t('logIn')}</span>
                </RedirectToExternalLinkButton>
              </div>
            </div>
          </MenuItems>
        </>
      )}
    </Menu>
  );
};
