import React from "react";

export const LanguageIcon = () => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.5"
        cy="12"
        r="10.75"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M16.25 12C16.25 15.0889 15.6496 17.8487 14.7125 19.8081C13.7491 21.8225 12.5641 22.75 11.5 22.75C10.4359 22.75 9.25091 21.8225 8.28751 19.8081C7.35037 17.8487 6.75 15.0889 6.75 12C6.75 8.91106 7.35037 6.15135 8.28751 4.19186C9.25091 2.17749 10.4359 1.25 11.5 1.25C12.5641 1.25 13.7491 2.17749 14.7125 4.19186C15.6496 6.15135 16.25 8.91106 16.25 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M1.5 12H22" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
};
