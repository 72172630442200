import React from "react";

export const Rectangle = () => {
  return (
    <svg
      width="1"
      height="26"
      viewBox="0 0 1 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="1" height="25" fill="#D9D9D9" />
    </svg>
  );
};
