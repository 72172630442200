"use client";

import { Button } from "./Button";

interface Props {
  children?: React.ReactNode;
  classes?: string;
  externalLink: string;
  btnType?: "primary" | "secondary" | "danger" | "black" | "custom";
}
const RedirectToExternalLinkButton = ({
  children,
  classes,
  externalLink,
  btnType = "primary",
}: Props) => {
  const redirectToProviderOnboarding = () => {
    window.location.href = externalLink;
  };
  const textColor = btnType === "custom" ? "black" : "white";
  return (
    <Button
      type='button'
      onClick={redirectToProviderOnboarding}
      className={classes}
      borderRadius='full'
      btnType={btnType}
      textColor={textColor}>
      {children}
    </Button>
  );
};

export default RedirectToExternalLinkButton;
