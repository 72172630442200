import React from 'react';
import RedirectToExternalLinkButton from '../../basics/RedirectToExternalLinkButton';
import {getBaseUrl} from '@website/lib/services/utils';
import {useLocale, useTranslations} from 'next-intl';

export interface CtaButtonProps {
  linkButton?: 'get-started' | 'request-demo';
}

export function CtaButton({linkButton}: CtaButtonProps): React.ReactElement {
  const t = useTranslations('Nav');
  const locale = useLocale();
  const isUs = !locale || locale === 'en-us';

  const getDirectBookingLink = () => {
    return `${getBaseUrl()}/${locale}/direct-booking/providers`;
  };

  return (
    <>
      {linkButton === 'get-started' ? (
        <RedirectToExternalLinkButton
          classes="w-full lg:w-auto bg-blue-600 hover:bg-blue-700 rounded-full px-4 py-3 text-white font-bold text-sm"
          externalLink={
            isUs ? `${getBaseUrl()}/get-started` : getDirectBookingLink()
          }
        >
          {t('getStarted')}
        </RedirectToExternalLinkButton>
      ) : (
        <RedirectToExternalLinkButton
          btnType="custom"
          classes="w-full lg:w-auto bg-pink-200 hover:bg-white hover:border-1 hover:border-pink-300 rounded-full px-4 py-3"
          externalLink={getDirectBookingLink()}
        >
          <span className="font-bold">{t('requestDemo')}</span>
        </RedirectToExternalLinkButton>
      )}
    </>
  );
}
